<template>
  <div id="divMenuWrapper" class="menu-wrapper" @click="closeMenu">
    <transition name="innerSlide">
      <div class="menu" v-show="getMenuStatus">
        <div ref="menuBody" class="menu-body">
          <div class="menu-close-btn" id="menuCloseBtn" @click.stop="closeMenu">
            <VueIcon iconName="IconCross" />
          </div>
          <VueAppbar
            v-if="!isMenuRoot"
            :title="getMenuTitle"
            :triggerBackButton="false"
            @backButtonEvent="getParentMenuList()"
            class="menu-bar"
          >
          </VueAppbar>
          <div
            class="menu-container brand-list"
            v-if="isMenuRoot && currentMenuItems.partners && !isImageRecognitionUser"
          >
            <VueText @click="goToPartner" sizeLevel="8" weightLevel="1" class="menuBrandTitle">{{
              currentMenuItems.partners > 1 ? 'İş Ortaklarım' : 'İş Ortağım'
            }}</VueText>
            <div class="menuBrandWrapper">
              <a
                v-for="partner in currentMenuItems.partners"
                @click="clickPartner(partner)"
                :key="partner.code"
                class="menuBrandItem"
              >
                <BrandLogo :hasBorder="false" :width="135" :height="90" :image="partner.image" />
              </a>
            </div>
          </div>
          <div class="menu-content">
            <VueMenuList v-if="currentMenuItems.menuItems" />
            <div
              v-if="currentMenuItems.isChangePosVisible"
              @click.stop="processChangedPos"
              class="pos-change"
            >
              Şube Değiştir
            </div>
            <VueButton
              class="logout-btn"
              @click.native.prevent="processLogout"
              :outlined="true"
              :iconName="getIcon('onOff').name"
              :iconColor="'#c60000'"
              >Çıkış Yap</VueButton
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { mapGetters } from 'vuex';
import StorageHelper from '@/utils/storageHelper';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import StorageProps from '@/mixins/storageProps.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import LoginMixin from '@/mixins/login.js';
import LocationMixin from '@/utils/locationUtils.js';
import { Account } from '@/services/Api/index';
import VueMenuList from './VueMenuList.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';

export default {
  name: 'VueMenu',
  mixins: [StorageProps, gtmUtils, LoginMixin, LocationMixin],
  components: {
    VueText,
    VueIcon,
    VueAppbar,
    BrandLogo,
    VueMenuList,
    VueButton,
  },
  created() {
    // Do not use the currentUser attribute directly as it makes the menu glitch during logout, which erases currentUser
    this.isImageRecognitionUser = this.currentUser.isImageRecognitionUser;
  },
  data() {
    return {
      menuBreadCrumb: [],
      currentParent: '',
      isMenuRoot: true,
      cookieModal: { show: false },
      isImageRecognitionUser: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.cookieModal.show = !this.isConsent;
    }, 0);
  },
  watch: {
    getMenuStatus: {
      handler(newVal) {
        if (!newVal) {
          this.$refs.menuBody.scrollTop = 0;
        } else {
          this.pushAds('small');
        }
      },
    },
    isConsent: {
      handler(last) {
        if (last !== 'NotSet') {
          this.cookieModal.show = !last;
        }
      },
    },
  },
  computed: {
    ...mapGetters('app', ['getCookieSettings', 'getMenuStatus']),
    ...mapGetters('auth', ['getUserModel']),
    isConsent() {
      return this.getCookieSettings ? this.getCookieSettings.isConsent : 'NotSet';
    },
    pmiRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi`;
    },
    pladisRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker`;
    },
    peymanRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman`;
    },
    horizonRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/horizon`;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    getRouteName() {
      return this.$route.name;
    },
    getMenuTitle() {
      if (!this.isMenuRoot) {
        return this.currentParent || this.getRouteName;
      }

      const storageUser = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY });

      if (storageUser) {
        return storageUser.get().name;
      }

      return '';
    },
  },
  methods: {
    processChangedPos() {
      Account.changedPos().then(res => {
        if (res?.data?.Data) {
          const pos = res.data.Data.pos;
          const posArr = Array.isArray(pos) ? pos : [pos];

          if (posArr.length > 1) {
            this.$store.dispatch('auth/setPosList', posArr).then(() => {
              this.closeMenu('divMenuWrapper');
              this.$emit('changedPos', true);
              this.$store.dispatch('app/setChangedPosToken', res.data.Data.token);
            });
          }
        }
      });
    },
    goToPartner() {
      if (
        this.currentMenuItems.partners.length === 1 &&
        this.currentMenuItems.partners[0].code === 'PMI'
      ) {
        if (this.$route.name !== 'business-partners-home') {
          this.$router.push(this.pmiRoute);
        } else {
          this.$store.dispatch('app/setMenuStatus', false);
        }
      }
    },
    clickPartner(partner) {
      this.pushDataLayerEvent('ecommerce');
      const partnerMap = {
        PMI: 'PMI',
        PLADIS: 'ÜLKER',
        PEY: 'PEYMAN',
        HORIZON: 'HORIZON',
      };
      this.pushDataLayerEvent('business_partner_click', {
        partner: partnerMap[partner.code] || partner.code,
      });

      const routeMap = {
        PMI: this.pmiRoute,
        PLADIS: this.pladisRoute,
        PEY: this.peymanRoute,
        HORIZON: this.horizonRoute,
      };
      const route = routeMap[partner.code];
      if (window.location.pathname === route) return;
      this.$router.push(route);
    },
    closeMenu(e) {
      if (
        e?.target?.id === 'divMenuWrapper' ||
        e?.currentTarget?.id === 'menuCloseBtn' ||
        e == 'divMenuWrapper'
      ) {
        this.$store.dispatch('app/setMenuStatus', false);
      }
    },
    getIcon(name) {
      return ICON_VARIABLES[name];
    },
    getParentMenuList() {
      this.isMenuRoot = true;
      this.$store.dispatch('app/setCurrentMenuItems', this.menuItems);
    },
  },
};
</script>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.menu-wrapper {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: 25;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
}
.menu {
  position: absolute;
  right: 0;
  background-color: palette-color-level('white', '100');
  width: 500px;
  overflow: hidden;
  height: 100%;
  top: 0;
  @media screen and (max-width: 1200px) {
    width: 60%;
  }
  .menu-body {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px;
    .menu-close-btn {
      position: absolute;
      right: palette-space-level(30);
      top: palette-space-level(30);
      cursor: pointer;
    }
    .menu-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
.pos-change {
  padding: 25px 0;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  border-bottom: 0.5px solid #b4c2d3;
  min-width: 400px;
}

.logout-btn {
  border: 2px solid palette-color('red-50');
  font-size: 20px;
  font-weight: 400;
  display: flex;
  color: palette-color('red-50');
  justify-content: center;
  border-radius: 40px;
  width: 250px;
  max-height: 55px;
  align-self: end;
  margin-top: 30px;

  &:hover {
    color: palette-color('red-50');
    border: 2px solid palette-color('red-50');
  }
}

.menu-bar {
  position: relative;
  display: flex;
  align-items: center;
}

.menuBrandTitle {
  margin-bottom: palette-space-level('20');
  cursor: pointer;
}

.menuBrandWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  width: 400px;

  .menuBrandItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-basis: calc(50% - 12.5px);
    min-height: 130px;
    box-shadow: inset 0 0 0 1px palette-color-level('grey', '15');
  }
}
.innerSlide-enter-active,
.innerSlide-leave-active {
  transition: all 0.3s ease-in-out;
}

.innerSlide-enter,
.innerSlide-leave-to {
  transform: translateX(100%);
}
</style>
